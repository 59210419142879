@import "~bootstrap/scss/bootstrap";
body {
  font-family: Inter;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-weight: normal;
  background-color: black;
  background-image: url(../assets/bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-top: 95px;
}
section {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

body {
  top: 0px !important;
}
.navbar {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  position: fixed; /* Fix the navbar to the top */
  top: 0; /* Position it at the top */
  left: 0;
  width: 100%; /* Make it span the entire width of the viewport */
  z-index: 1000; /* Ensure it stays on top of other content */
  background-color: #343a40; /* Match the background color */
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-brand {
    margin-right: 100px;
    @media (max-width: 1200px) {
      margin-right: 50px;
    }
    img {
      height: 22px;
    }
  }
  .nav-item {
    margin: 0 10px;
  }
  .nav-link {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    border-bottom: 3px solid transparent;
    &:hover {
      border-bottom: 3px solid #72f1c8;
    }
  }
  #google_translate_element {
    position: relative;
    width: 140px;
    height: 42.5px;
    margin-right: 12px;
    @media (max-width: 992px) {
      margin: auto;
    }
  }

  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }
  .skiptranslate.goog-te-gadget {
    color: black;
    margin-top: -5px;
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 14px;
    width: 100%;
    span {
      display: none;
    }
  }
  .goog-te-combo {
    display: flex;
    padding: 13px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: black;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
    width: 140px;
    span {
      display: none;
    }
  }
  .dropdown-toggle {
    display: flex;
    padding: 13px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
    svg {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
    @media (max-width: 575px) {
      margin: 0;
    }
  }
  .dropdown-menu {
    width: 100%;
    border: 1px solid;
    background-color: black;
    .dropdown-item {
      color: white;
      padding: 10px 10px;
      &:hover {
        color: black;
      }
    }
  }
  .btn-primary {
    display: flex;
    padding: 13px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: linear-gradient(90deg, #72f1c8 0%, #1a5853 100%);
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 992px) {
      margin: auto;
      margin-top: 20px;
    }
    @media (max-width: 575px) {
      margin-top: 20px;
      width: 100%;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #000;
      scale: 1.05;
      background: linear-gradient(90deg, #1a5853 0%, #60aaf5 100%);
    }
  }
}

.bg-dark {
  background-color: black !important;
}
.hero {
  text-align: left;
  @media (max-width: 992px) {
    background-position: left;
  }
  .hero-left {
    position: relative;
    left: 100px;
    width: calc(100% - 100px);
    margin-bottom: 50px;
    @media (max-width: 992px) {
      left: 0;
      width: calc(100%);
    }
  }
  .social-icons {
    position: absolute;
    left: -100px;
    top: -90px;
    @media (max-width: 992px) {
      // top: -30px;
      display: none;
    }
    // @media (max-width: 575px) {
    //   left: -50px;
    // }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px 0;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    img {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.1;
      }
    }
  }
  .text-center-local {
    text-align: left;
  }
  @media (max-width: 992px) {
    .text-center-local {
      text-align: center;
    }
  }
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    text-shadow: 2px 2px black;
    @media (max-width: 575px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  p {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-shadow: 2px 2px black;
  }
  .btn-primary {
    display: inline-flex;
    padding: 13px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: linear-gradient(0deg, #1a5853 0%, #1a5853 100%);
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.05;
      background: linear-gradient(90deg, #1a5853 0%, #60aaf5 100%);
    }
  }
  .btn-secondary {
    display: inline-flex;
    padding: 13px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #1a5853 0%, #60aaf5 100%);
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.05;
      background: linear-gradient(0deg, #1a5853 0%, #1a5853 100%);
    }
  }
}
.widget {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #0b1c1a;
  width: 410px;
  flex-shrink: 0;
  @media (max-width: 992px) {
    margin: auto;
  }
  @media (max-width: 575px) {
    width: 360px;
  }
  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    b {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.4%; /* 14.896px */
    }
  }
  .timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(45, 45, 45, 0);
    background: radial-gradient(
      231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(20px);
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      border-radius: 10px;
      padding: 1px;
      background: linear-gradient(
        to right,
        #60aaf5 0%,
        #317800 55.83%,
        #1a5853 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .digits {
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 106.4%; /* 42.56px */
      }
      .unit {
        color: #d9d9d9;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 106.4%; /* 10.64px */
      }
    }
    .dots {
      color: #d9d9d9;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 106.4%; /* 51.072px */
    }
  }
  .progress {
    border-radius: 8px;
    background: #777;
    height: 20px;
    position: relative;
    margin-bottom: 10px;

    .progress-bar {
      border-radius: 8px;
      background: #57b597;
    }
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #d9d9d9;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.4%; /* 14.896px */
    }
  }
  .raised {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 106.4%; /* 14.896px */
    margin-bottom: 10px;

    b {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.4%;
    }
  }
  .balance {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(45, 45, 45, 0);
    background: radial-gradient(
      231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(20px);
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      border-radius: 10px;
      padding: 1px;
      background: linear-gradient(
        to right,
        #60aaf5 0%,
        #317800 55.83%,
        #1a5853 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    b {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 106.4%; /* 14.896px */
    }
    .claim-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .claim-text {
        b {
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 106.4%; /* 17.024px */
        }
        span {
          color: #fff;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 106.4%; /* 14.896px */
        }
      }
      .btn-claim {
        display: inline-flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid #317800;
        background: #000;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 106.4%; /* 14.896px */
      }
    }
  }
  .equal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 106.4%; /* 12.768px */
    margin-bottom: 20px;
  }
  .currencies {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .currency {
      cursor: pointer;
      display: inline-flex;
      padding: 8px 15px;
      min-height: 40px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 10px;
      border: 1px solid #317800;
      background: #000;
      &.active {
        border: 2px solid #317800;
      }
      // transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.02;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        &.rounded {
          border-radius: 30px !important;
        }
      }
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 106.4%; /* 12.768px */
    }
  }
  label {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 106.4%; /* 12.768px */
  }
  .input-wrapper {
    height: 36px;
    border-radius: 10px;
    background: #2d2d2d;
    position: relative;
    margin-bottom: 10px;
    img {
      position: absolute;
      top: 8px;
      right: 10px;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      &.BUSD {
        border-radius: 20px;
      }
    }
    .form-control {
      height: 36px;
      border-radius: 10px;
      background: #2d2d2d;
      border: none;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 106.4%; /* 12.768px */
      box-shadow: none;
    }
  }
  .btn-buy {
    display: block;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    background: linear-gradient(260deg, #1a5853 0.69%, #60aaf5 99.31%);
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    border: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.01;
      background: linear-gradient(90deg, #1a5853 0%, #60aaf5 100%);
    }
  }
}
.standards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .standards-left {
    width: 50%;
    display: flex;
    text-align: start;
    @media (max-width: 992px) {
      width: 100%;
    }
    img {
      width: 100%;
      @media (max-width: 992px) {
        width: 80%;
        margin: auto;
        margin-bottom: 30px;
      }
    }
  }
  .standards-right {
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 992px) {
      align-items: center;
      width: 100%;
    }
    h1 {
      color: #72f1c8;
      // font-family: Raleway;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 30px;
      @media (max-width: 992px) {
        font-size: 30px;
        line-height: 41px;
        text-align: center;
      }
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      @media (max-width: 992px) {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
      }
    }
    .btn-primary {
      border-radius: 10px;
      border: 1px solid #60aaf5;
      background: #000;
      color: #fff;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 20px 20px 0;
      svg {
        margin-left: 10px;
      }
      transition: all 0.2s ease-in-out;
      &:hover {
        background: linear-gradient(90deg, #1a5853 0%, #60aaf5 100%);
      }
    }
  }
}
.why {
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    img {
      width: 50px;
      height: 50px;
      margin-right: 30px;
    }
  }
  p {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 40px;
    b {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      @media (max-width: 992px) {
        font-size: 17px;
        line-height: 50px;
      }
    }
  }
  .boxes {
    display: flex;
    align-items: stretch;
    justify-content: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    .box {
      position: relative;
      padding: 20px 30px 30px 30px;
      text-align: start;
      @media (max-width: 992px) {
        text-align: center;
        padding: 30px 30px 30px 30px;
      }
      &:nth-child(3) {
        &::after {
          content: none;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 2px; /* Adjust the width as needed */
        background: radial-gradient(
          6104.39% 7997.58% at 0% 2200%,
          #60aaf5 0%,
          #317800 55.83%,
          #1a5853 100%
        );
      }
      @media (max-width: 992px) {
        &:nth-child(3) {
          &::after {
            content: "";
          }
        }
        &::after {
          top: 2px;
          width: 100%; /* Adjust the width as needed */
          height: 2px; /* Adjust the width as needed */
        }
      }
      img {
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
      }
      h2 {
        color: #fff;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 30px;
      }
      p {
        color: #fff;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 0;
      }
    }
  }
}
.roadmap {
  h1 {
    color: #72f1c8;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .years {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0 50px;
    @media (max-width: 992px) {
      display: none;
    }
    h2 {
      color: #aba8a8;
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      position: relative;
      margin: 0 30px;
      @media (max-width: 992px) {
        font-size: 13px;
      }
      &.active {
        color: #94fffd;
      }
      &::after {
        content: "";
        position: absolute;
        right: -15px;
        top: 13px;
        width: 6px;
        height: 6px;
        border-radius: 20px;
        background: #aba8a8;
      }
      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 13px;
        width: 6px;
        height: 6px;
        border-radius: 20px;
        background: #aba8a8;
      }
    }
  }

  h3 {
    color: #94fffd;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }
  ul {
    margin-left: 0;
    padding-left: 15px;
    li {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }
  .boxes {
    padding: 50px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    background-image: url(../assets/bg-2.png);
    @media (max-width: 992px) {
      padding: 30px 0 0;
    }
  }
  .box {
    text-align: start;
    padding: 40px 50px;
    border-radius: 20px;
    margin: 20px;
    @media (max-width: 575px) {
      padding: 20px;
      margin: 20px 0;
    }
    background: radial-gradient(
      231% 135.8% at 0.9% 11.67%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    // border: 2px solid #317800;
    backdrop-filter: blur(20px);
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      border-radius: 20px;
      padding: 2px;
      background: linear-gradient(
        to right,
        #60aaf5 0%,
        #317800 55.83%,
        #1a5853 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
.tokenomics {
  h1 {
    color: #72f1c8;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: 992px) {
      margin-bottom: 50px;
    }
  }
  img {
    max-width: 100%;
  }
}
.partner {
  h1 {
    color: #72f1c8;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
    margin-bottom: 50px;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  .partners {
    display: flex;
    align-items: center;
    gap: 15px 20px;
    flex-wrap: wrap;
    justify-content: start;
    @media (max-width: 992px) {
      justify-content: center;
    }
    a {
      display: flex;
      padding: 5px;
      border-radius: 10px;
      border: 1px solid #bacd84;
      background: #000;
      color: #fff;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
  .box {
    padding: 50px;
    @media (max-width: 992px) {
      padding: 50px 30px;
    }
    border-radius: 10px;
    border: 2px solid white;
    background: radial-gradient(
      231% 135.8% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(25px);
    margin: 30px 0;
    p {
      color: #fff;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    img {
      width: 310px;
      height: 217px;
    }
  }
}
.team {
  text-align: start;
  h1 {
    color: #72f1c8;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
  }
  p {
    color: #72f1c8;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
    margin-bottom: 100px;
  }
  .member {
    display: flex;
    gap: 50px;
    margin-bottom: 40px;
    @media (max-width: 575px) {
      gap: 20px;
      align-items: center;
      flex-direction: column;
    }
  }
  img {
    width: 149px;
    height: 149px;
    flex-shrink: 0;
    border-radius: 1000px;
    border: 1px solid #000;
  }
  h2 {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  small {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    @media (max-width: 575px) {
      display: block;
      text-align: justify;
    }
  }
}
.faq {
  padding: 100px;
  background: #1f887e;
  @media (max-width: 575px) {
    padding: 50px 20px;
  }
  .outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    .content-container {
      width: 50%;
      display: flex;
      text-align: start;
      flex-direction: column;
      @media (max-width: 992px) {
        text-align: center;
        width: 100%;
      }
    }
    .image-container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 992px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 100px;
  }
  p {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 50px;
  }
  .accordion-collapse {
    color: white;
    font-size: 16px;
    background-color: #1f887e;
  }
  .accordion-button {
    color: white;
    padding: 15px 0;
    border-bottom: 1px solid white;
    background-color: #1f887e;
    box-shadow: none;
    &::after {
      filter: invert(1);
    }
  }
  .accordion-body {
    line-height: 20px;
    padding: 15px 0;
  }
}
footer {
  padding: 100px 0 300px 0;
  background: black;
  .logo {
    width: 61px;
    height: 61px;
    flex-shrink: 0;
    margin-bottom: 60px;
  }
  ul {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    padding: 0;
    list-style: none;
    @media (max-width: 575px) {
      gap: 15px;
    }
    li {
      a {
        color: #fff;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
        line-height: normal;
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;
        @media (max-width: 575px) {
          font-size: 14px;
        }
        &:hover {
          color: #aba8a8;
          border-bottom: 1px solid #72f1c8;
        }
      }
    }
  }
  p {
    color: #aba8a8;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0 20px;
    a {
      color: #aba8a8;
      &:hover {
        color: white;
      }
    }
  }
  .line {
    width: 100%;
    margin: 10px 0 20px;
    border-bottom: 1px solid #ffffff;
  }
  .social-icons {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;
    list-style: none;
    margin: 0 30px;
    img {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.05;
      }
    }
  }
}
